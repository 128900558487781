import "./styles.css";
import React from 'react';
import { ScoutBar } from "scoutbar/dist/bundle-cjs";
import { createScoutAction,createScoutSection } from 'scoutbar';
export default function Bar() {
  return (
    <div className="App">
      <ScoutBar
        aknowledgement={false}
        placeholder="How can I help you to reach?"  
        actions=
        {
          ({ createScoutAction }) => [
          createScoutAction({
            label: "Social Media",
          }), 
          createScoutAction({
            icon: "https://img.icons8.com/windows/452/twitch.png",
            label: "Twitch",
            description: "Watch Cody's Stream now!",
            href: "https://www.twitch.tv/captain_meerkat",
            keyboardShortcut: ["t"]
          }),
          createScoutAction({
            icon:"https://img.icons8.com/windows/452/twitter.png",
            label: "Twitter",
            description: "Follow me on Twitter!",
            href: "https://twitter.com/Cpt_Meerkat_",
            keyboardShortcut: ["w"]
          }),
          createScoutAction({
            icon:"https://img.icons8.com/ios-glyphs/452/facebook-new.png",
            label: "FaceBook",
            description: "@CodyInTaiwan",
            href: "https://www.facebook.com/Codyintaiwan",
            keyboardShortcut: ["f"]
          }),
          createScoutAction({
            icon:"https://img.icons8.com/color/452/youtube-play.png",
            label: "Youtube",
            description: "Subscribe!",
            href: "https://www.youtube.com/channel/UCWmMUcmtc6pO_C2okY9SDeA",
            keyboardShortcut: ["l"]
          }),
          createScoutAction({
            icon: "https://img.icons8.com/material-sharp/452/patreon.png",
            label: "Patreon",
            href: "https://www.patreon.com/captain_meerkat",
            keyboardShortcut: ["P"]
          }),
          createScoutAction({
            icon: "https://www.opay.tw/Content/themes/copartnership_store/images/frame/allPay_icon.png",
            label: "歐付寶",
            description: "感蝦哩ㄟ扣扣!你人真好!謝謝你支持我的遊戲直播 歐付寶會員編號:2310413",
            href: "https://payment.opay.tw/Broadcaster/Donate/493838B1B82BAEB00A0327547FE0EFE7",
            keyboardShortcut: ["O"]
          }),
          createScoutAction({
            icon: "https://dixper.gg/assets/cookies.svg",
            label: "Dixper",
            href: "https://dixper.gg/Captain_Meerkat",
            keyboardShortcut: ["D"],
            description:"人家怎麼控制我的台就是用這個網站, 歡迎你來 This is how chat can control my channel."
          }),
          createScoutAction({
            label: "Author",
          }),
          createScoutAction({
            icon:"https://img.icons8.com/windows/452/twitch.png",
            label: "K1ssY0u",
            description: "This is a Fan site not Official Website :D",
            href: "https://twitch.tv/k1ssy0u",
            keyboardShortcut: ["k"]
          }),
        ]
      }
      />
      
    </div>
  );
}
